export const COMPANY = {
  KHV: {
    PHONE: '+74212606460',
    EMAIL: '1@studentsa.ru',
    CITY: 'Хабаровск',
  },
  KOMS: {
    PHONE: '+74217517251',
    CITY: 'Комсомольск-на-Амуре',
  },
  VK: 'https://vk.com/studentsa_khab',
  INSTA: 'https://instagram.com/students_service',
  YOUTUBE: 'https://www.youtube.com/channel/UC8xeLS5ppS4k6uChp2K0BSQ',
  TELEGRAM: 'user_name',
  WHATSAPP: '+79622206460',
  SKYPE: 'kat_students',
};

export const INTERSHIPTEXT =
  // tslint:disable-next-line: max-line-length
  // tslint:disable-next-line: quotemark
  "Вы давно задумываетесь о том, чтобы получить опыт работы за рубежом, но не знаете, каким образом это осуществить? Воплотите свои мечты по программам стажировок и работы за границей от хабаровской компании Student's Service, которые помогут вам не только повысить уровень владения иностранным языком, но и получить необходимые профессиональные навыки.\n\nМы предлагаем вам отправиться за границу по программам Work and Travel (Работа и Путешествие), Work and Study (Стажировка и Учеба), а также других стажировок и работы в странах Европы, Новой Зеландии и Северной Америки.";

export const VISATEXT =
  // tslint:disable-next-line: max-line-length
  'Запланировали поездку за рубеж? Требуется оформить визу в Хабаровске (туристическую, студенческую или рабочую)? Компания Student’s Service обязательно поможет вам в этом! Мы оформили множество виз в США, Канаду, Австралию, Японию, Шенгенские визы, Китай, а также многие другие страны.';

export const VACATIONSTEXT =
  // tslint:disable-next-line: max-line-length
  'Чтобы  Каникулы  прошли для вашего ребенка с пользой и морем положительных эмоций, подарите ему возможность изучения английского на каникулах в зарубежных языковых центрах. Компания Student’s Service предлагает различные варианты программ языковых курсов на время каникул за рубежомдля школьников разных возрастов.';

export const ABOUTCOMPANYTEXT =
  // tslint:disable-next-line: max-line-length
  'При выборе учебного заведения важно соблюсти все до мелочей. Независимо от страны обучения за рубежом, мы предложим самые актуальные и перспективные программы образования. Наша специализация — оказание консультационной и практической помощи в организации образовательных поездок российских граждан за рубеж. В нашей базе данных имеется самый широкий выбор образовательных программ: от коротких языковых курсов до получения академического образования в ведущих ВУЗах мира, от учебно-развлекательных каникулярных программ для школьников и студентов до программ МВА для топ-менеджеров';
