<app-dynamic-form [fields]="fields$ | async" (save)="save($event)"></app-dynamic-form>

<table mat-table [dataSource]="items$ | async">
  <ng-container matColumnDef="{{ column.field }}" *ngFor="let column of tableColumns$ | async">
    <th mat-header-cell *matHeaderCellDef>{{ column.name }}</th>
    <td mat-cell *matCellDef="let element">{{ column.data(element) }}</td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Действия</th>

    <td mat-cell *matCellDef="let element">
      <button mat-icon-button (click)="edit(element)">
        <mat-icon>edit</mat-icon>
      </button>

      <button mat-icon-button (click)="delete(element.id)">
        <mat-icon>delete</mat-icon>
      </button>
    </td>
  </ng-container>

  <ng-container *ngIf="columnsNames$ | async as columns">
    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns"></tr>
  </ng-container>
</table>
