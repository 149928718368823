<div [formGroup]="form" fxLayout="column">
  <ng-container [ngSwitch]="field.controlType">

    <mat-form-field *ngSwitchCase="'textbox'">
      <input [formControlName]="field.key" [id]="field.key" [type]="field.type" autocomplete="off"
             matInput [placeholder]="field.label">
      <mat-hint *ngIf="field.hint">{{field.hint}}</mat-hint>
    </mat-form-field>

    <mat-form-field *ngSwitchCase="'numberfield'">
      <input [formControlName]="field.key" [id]="field.key" type="number" autocomplete="off"
             matInput [placeholder]="field.label">
    </mat-form-field>

    <mat-form-field *ngSwitchCase="'timefield'">
      <input [owlDateTime]="eventTimeOwl" [owlDateTimeTrigger]="eventTimeOwl" matInput
             [placeholder]="field.label" [formControlName]="field.key" autocomplete="off">

      <owl-date-time #eventTimeOwl [pickerType]="'timer'"></owl-date-time>
    </mat-form-field>

    <mat-form-field *ngSwitchCase="'datefield'">
      <mat-label>{{ field.label }}</mat-label>
      <input matInput [matDatepicker]="picker" [formControlName]="field.key" autocomplete="off">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <app-file-uploader [formControlName]="field.key" *ngSwitchCase="'filefield'"
                       [directory]="field.directory" [placeholder]="field.label"
                       [hint]="field?.hint">
    </app-file-uploader>

    <mat-form-field *ngSwitchCase="'selectfield'">
      <mat-label>{{field.label}}</mat-label>

      <mat-select [formControlName]="field.key">
        <mat-option *ngFor="let option of field?.options | async"
                    [value]="option[field.optionValue]">
          {{option[field.optionLabel]}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <ng-container *ngSwitchCase="'htmleditorfield'">
      <angular-editor [formControlName]="field.key"></angular-editor>
    </ng-container>

    <mat-slide-toggle *ngSwitchCase="'booleanfield'" [formControlName]="field.key">
      {{field.label}}
    </mat-slide-toggle>

  </ng-container>
</div>
