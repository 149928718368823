export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyASdVhDauan6dF_MPzUIR6D1_FkVLQSr-M',
    authDomain: 'student-service-517a8.firebaseapp.com',
    databaseURL: 'https://student-service-517a8.firebaseio.com',
    projectId: 'student-service-517a8',
    storageBucket: 'student-service-517a8.appspot.com',
    messagingSenderId: '139271507123'
  }
};
